<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <div class="d-flex align-center pt-3">
                    <v-text-field
                        :placeholder="$t('message.no')"
                        :value="searchTerm"
                        class="filter-width-175 force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-select
                        :items="years"
                        :loading="loading.filter.years"
                        :placeholder="$t('message.year')"
                        autocomplete="off"
                        class="filter-width-175 ml-2"
                        dense
                        hide-details="auto"
                        item-text="Year.text"
                        item-value="Year.id"
                        required
                        solo
                        v-model="filterOptions.year"
                        @change="loadSalesOrders()"
                    ></v-select>
                    <v-select
                        :items="months"
                        :loading="loading.filter.months"
                        :placeholder="$t('message.allMonths')"
                        autocomplete="off"
                        class="filter-width-175 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Month.text"
                        item-value="Month.id"
                        required
                        solo
                        v-model="filterOptions.month"
                        @change="loadSalesOrders()"
                    >
                        <template v-slot:selection="{item}">
                            {{ item.Month.code }}
                        </template>
                    </v-select>
                    <v-select
                        :items="offices"
                        :loading="loading.filter.offices"
                        :placeholder="$t('message.allOffices')"
                        autocomplete="off"
                        class="filter-width-175 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Office.id"
                        item-text="Office.code"
                        solo
                        v-model="filterOptions.office"
                        @change="loadSalesOrders()"
                    >
                        <template v-slot:selection="{item}">
                            {{ item.Office.code }}
                        </template>
                    </v-select>
                    <v-autocomplete
                        :items="salesOrderBuyers"
                        :loading="loading.filter.buyers"
                        :placeholder="$t('message.allBuyers')"
                        autocomplete="off"
                        class="filter-width-175 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Customer.id"
                        item-text="Customer.title"
                        solo
                        v-model="filterOptions.buyer"
                        @change="loadSalesOrders()"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                        :items="salesOrderSalesColleagues"
                        :loading="loading.filter.salesColleagues"
                        :placeholder="$t('message.allSalesColleagues')"
                        class="filter-width-175 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Salescontact.id"
                        item-text="Salescontact.name"
                        solo
                        v-model="filterOptions.salesColleague"
                        @change="loadSalesOrders()"
                    >
                    </v-autocomplete>
                    <v-btn
                        :loading="loading.clear"
                        class="ml-2 px-1 mr-2"
                        @click="resetFilters"
                    >{{ $t('message.clear') }}</v-btn>
                </div>
                <div class="d-flex flex-row align-center pt-3 ml-5">
                    <div class="font-sm">
                        <v-icon small color="grey lighten-2">fiber_manual_record</v-icon>{{ $t('message.unsigned') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="orange lighten-2">fiber_manual_record</v-icon>{{ $t('message.signed') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="green lighten-2">fiber_manual_record</v-icon>{{ $t('message.converted') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="red lighten-2">fiber_manual_record</v-icon>{{ $t('message.cancelled') }}
                    </div>
                </div>
                <v-spacer></v-spacer>
                <ExportTableJson
                    :export-conditions="exportConditions"
                    :export-data="SalesOrders"
                    :export-fields="headers"
                    :export-source="'salesorders'"
                    class="ml-3"
                ></ExportTableJson>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.salesOrders"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions,
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :items="SalesOrders"
            :options.sync="tableOptions"
            :search="searchTerm"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            id="salesOrdersTable"
            item-key="SalesOrder.id"
            sort-by="SalesOrder.sodate"
            sort-desc
        >
            <template v-slot:item.SalesOrder.title="{item}">
                <span class="font-weight-bold">{{ item.SalesOrder.title }}</span>
            </template>
            <template v-slot:item.SalesOrder.sodate="{item}">
                <span>{{ formatDate(item.SalesOrder.sodate) }}</span>
            </template>
            <template v-slot:item.SalesOrder.value="{item}">
                <div class="text-left">{{ currency(item.SalesOrder.currency_id) + " " + formatThisNumber(item.SalesOrder.value,findCurrencyPrecision(item.SalesOrder.currency_id)) }}</div>
            </template>
            <template v-slot:item.SalesOrder.orderstatus_id="{ item }">
                <div class="text-center">
                    <v-icon small :color="item.SalesOrder.Status.color + ' lighten-2'">fiber_manual_record</v-icon>
                </div>
            </template>
            <template v-slot:item.SalesOrder.date_signed="{item}">
                <span>{{ formatDate(item.SalesOrder.date_signed) }}</span>
            </template>
            <template v-slot:item.SalesOrder.Invoice.title="{item}">
                <span>{{ item.SalesOrder.Invoice.title }}</span>
            </template>
            <template v-slot:item.SalesOrder.id="{ item }">
                <div  class="d-flex flex-row align-center justify-center" style="height: 100%">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="updateSalesOrder(item.SalesOrder.id)" v-if="$can('update','SalesOrder')">
                                <v-icon small class="mr-1" v-if="item.SalesOrder.orderstatus_id == 2">far fa-eye</v-icon>
                                <v-icon color="black lighten-1" small class="mr-1" v-else>edit</v-icon>
                                {{ item.SalesOrder.orderstatus_id == 2 ? $t('message.viewSalesOrder') : $t('message.updateSalesOrder') }}
                            </v-list-item>
                            <v-list-item v-if="item.SalesOrder.orderstatus_id == 1 && $can('delete','SalesOrder')" class="font-sm" @click="cancelSalesOrder(item.SalesOrder.id, item.SalesOrder.title)">
                                <v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>
                                {{ $t('message.cancelSalesOrder') }}
                            </v-list-item>

<!--                            <v-list-item class="font-sm" @click="viewPdf(item.SalesOrder.id, 'so')" v-if="$can('read','SalesOrder')">-->
<!--                                <v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.salesOrder') }}-->
<!--                            </v-list-item>-->
<!--                            <v-list-item class="font-sm" @click="viewPdf(item.SalesOrder.id, 'pi')" v-if="$can('read','SalesOrder')">-->
<!--                                <v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.proformaInvoice') }}-->
<!--                            </v-list-item>-->
                            <PrintSalesOrderButtons
                                :sales-order-id="item.SalesOrder.id"
                                :update-mode="true"
                                :list-mode="true"
                                :version="item.SalesOrder.item_list_version"
                                v-if="$can('read','SalesOrder')"
                            />
                            <v-list-item class="font-sm" @click="viewInvoice(item.SalesOrder.Invoice.id)" v-if="$can('read','LocalSale') && item.SalesOrder.Invoice.id != null">
                                <v-icon small class="mr-1">far fa-eye</v-icon>{{ $t('message.viewInvoice') }}
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-data-table>
        <SimpleAlert
            :alert_message.sync="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        >
        </SimpleAlert>
    </div>
</template>

<script>
    import {formatDate, numberFormat} from "Helpers/helpers";
    // import SimpleAlert from "Components/Appic/SimpleAlert";
    import {mapGetters, mapActions} from "vuex";
    import {mapFields} from "vuex-map-fields";
    import { v4 as uuidv4 } from 'uuid';

    const ExportTableJson = () => import("Components/Appic/ExportTableJson");
    const SimpleAlert = () => import("Components/Appic/SimpleAlert");
    const PrintSalesOrderButtons = () => import("Components/Appic/Printing/PrintSalesOrderButtons");

    export default {
        name: "SalesOrdersListing",
        components: {PrintSalesOrderButtons, ExportTableJson, SimpleAlert},
        title: '',
        data(){
            return {
                defaultFilterOptions: {
                    buyer: null,
                    office: null,
                    salesColleague: null,
                    month: null,
                    year: null,
                },
                dialogs: {
                    error: false,
                    error_message: ""
                },
                exportConditions: {},
                filterOptions: {
                    buyer: null,
                    office: null,
                    salesColleague: null,
                    month: null,
                    year: null,
                },
                hideDefaultFooter: true,
                loading: {
                    fetch: false,
                    filter: {
                        buyers: false,
                        months: false,
                        offices: false,
                        salesColleagues: null,
                        years: false,
                    },
                    filterResults: false,
                    salesOrders: false
                },
                SalesOrders: [],
                searchField: null,
                searchTerm: null,
                tableOptions: {
                    page: 1
                },
                totalSalesOrders: 0
            }
        },
        computed: {
            ...mapFields('salesorder',{
                salesOrderBuyers: 'current.salesOrderBuyers',
                salesOrderSalesColleagues: 'current.salesOrderSalesColleagues'
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters([
                'currencies',
                'months',
                'offices',
                'salesTypes'
            ]),
            headers () {
                let headers = [
                    {
                        id: 0,
                        text: this.$t('message.actions'),
                        value: 'SalesOrder.id',
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct'
                    },
                    {
                        id: 1,
                        text: this.$t('message.date'),
                        value: 'SalesOrder.sodate',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 2,
                        text: this.$t('message.salesOrder'),
                        value: 'SalesOrder.title',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 3,
                        text: this.$t('message.status'),
                        value: 'SalesOrder.orderstatus_id',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-center width-1-pct'
                    },
                    {
                        id: 4,
                        text: this.$t('message.company'),
                        value: 'SalesOrder.Office.title',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 5,
                        text: this.$t('message.salesColleague'),
                        value: 'SalesOrder.Salescontact.name',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 6,
                        text: this.$t('message.buyer'),
                        value: 'SalesOrder.Customer.title',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 7,
                        text: this.$t('message.dateSigned'),
                        value: 'SalesOrder.date_signed',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 8,
                        text: this.$t('message.value'),
                        value: 'SalesOrder.value',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-right'
                    },
                    {
                        id: 9,
                        text: this.$t('message.invoiceNo'),
                        value: 'SalesOrder.Invoice.title',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    }
                ]
                return headers
            },
            itemsPerPageOptions() {
                let options = [10,20,50,-1];
                if(window.screen.height >= 800){
                    options = [20,40,50,-1];
                }
                return options;
            },
            searchFields() {
                return this.headers.filter((header)=>header.searchable === true)
            },
            years() {
                let array = []
                let currentYear = new Date().getFullYear()
                let startYear = currentYear - 5
                while(currentYear > startYear){
                    array.push({Year: {text: currentYear.toString(), id: currentYear}})
                    currentYear = currentYear - 1
                }
                return array
            }
        },
        methods: {
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('salesorder',{
                cancelSalesOrderById: 'cancelSalesOrderById',
                getAllSalesOrders: 'getAllSalesOrders',
                getAllSalesOrderBuyers: 'getAllSalesOrderBuyers',
                getAllSalesOrderSalesColleagues: 'getAllSalesOrderSalesColleagues',
                resetOverviewState: 'resetOverviewState'
            }),
            async cancelSalesOrder (val, title) {
                if(await this.$root.$confirm(this.$t('message.cancelSalesOrder') + ' ' + (title != null ? title : ''), this.$t('message.confirmations.continueSalesOrderCancelAction'), {color: 'orange'})){
                    this.cancelSalesOrderById(val)
                        .then((response) => {
                            if(response.status == 'success'){
                                this.$toast.success(this.$t('message.successes.salesOrderDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loadSalesOrders()
                            } else {
                                this.$toast.error(this.$t('message.errors.salesOrderNotDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(()=>{
                            this.$toast.error(this.$t('message.errors.salesOrderNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            },
            currency(currency_id) {
                return this.allCurrencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            filterResults () {
                this.loading.filterResults = true
                let _this = this
                setTimeout(function(){
                    _this.loading.filterResults = false
                },700)
            },
            findOffice (val) {
                return this.offices.find(o => o.Office.id == val)
            },
            findCurrencyPrecision (val) {
                let currency = this.allCurrencies.find(c => c.Currency.id == val)
                return currency?.Currency?.formatPrecision
            },
            formatDate,
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            loadSalesOrders () {
                this.loading.salesOrders = true
                this.loading.fetch = true
                let payload = {
                    filterOptions: this.filterOptions,
                }
                this.getAllSalesOrders(payload)
                    .then((tableData) => {
                        this.SalesOrders = tableData.rows
                        this.totalSalesOrders = tableData.totalRows
                        if(this.totalSalesOrders == 0){
                            this.filterOptions.year = this.filterOptions.year - 1
                            this.loadSalesOrders()
                        }
                    })
                    .catch(() => {
                        this.loading.salesOrders = false
                        this.loading.fetch = false;
                    })
                    .finally(() => {
                        this.loading.salesOrders = false
                        this.loading.fetch = false
                        this.tableOptions.page = 1
                    })
            },
            resetFilters () {
                this.loading.reset = true
                this.filterOptions = _.cloneDeep(this.defaultFilterOptions)
                let d = new Date()
                this.filterOptions.year = d.getFullYear()
                this.loadSalesOrders()
                this.loading.reset = false
            },
            updateSalesOrder (val){
                let tab = window.open('/v1/salesorders/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewPdf (salesOrderId, docType) {
                let document = encodeURIComponent(this.SalesOrders.find( so => so.SalesOrder.id == salesOrderId )?.SalesOrder.title)
                let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/salesorders/print/'
                    + salesOrderId
                    + '/'
                    + docType
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewInvoice (val) {
                let tab = window.open('/v1/localsales/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        watch: {
            searchTerm() {
                this.tableOptions.page = 1
            }
        },
        created() {
            // this.resetOverviewState()getFullYear
            let d = new Date()
            this.filterOptions.year = d.getFullYear()

            if(this.allCurrencies.length == 0) this.getAllCurrencies()

            if(this.salesOrderBuyers.length == 0) {
                this.loading.filter.buyers = true
                this.getAllSalesOrderBuyers()
                    .then(() => {
                        this.loading.filter.buyers = false
                    })
            }
            if(this.salesOrderSalesColleagues.length == 0){
                this.loading.filter.salesColleagues = true
                this.getAllSalesOrderSalesColleagues()
                    .then(() => {
                        this.loading.filter.salesColleagues = false
                    })
            }
            this.loadSalesOrders()
        },
        mounted() {
            this.$title = this.$t('message.titles.salesOrders')
        }
    }
</script>

<style>
.filter-width-175 {
    width: 175px !important;
    max-width: 175px !important;
}
.border-top-1 {
    border-top: 1px solid lightgray !important;
}
.col-0-75 {
    flex: 0 0 6.25%;
    max-width: 6.25%;
}
.col-1-5 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
}
.col-3-5 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
}
.offset-0-5 {
    margin-left: 4.16666667%;
}
.v-dialog {
    min-width: 200px !important;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.font-xs {
    font-size: 0.60rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table-header th {
    white-space: nowrap !important;
}
</style>